import React from 'react';
import WizardDealSummary from './WizardDealSummary';
import WizardSpotForm from './WizardSpotForm';

export default class WizardTradeScreen extends React.Component {
    
    state = {
    }

    componentDidMount(){
    }
    
    render(){
        var dealScreen;
        
        dealScreen = (<WizardSpotForm id="comp-wizard-spot-form"
            bankCustomers={this.props.bankCustomers}
            objCustomer={this.props.objCustomer}
            objContact={this.props.objContact}
            objDealHeader={this.props.objDealHeader}
            paymentCurrencies={this.props.paymentCurrencies}
            currencyPairs={this.props.currencyPairs}
            settlementCurrencies={this.props.settlementCurrencies}
            customerCurrencyDictionary={this.props.customerCurrencyDictionary}
            onUpdateDealHeader={this.props.onUpdateDealHeader}
            onNextStepClicked={this.props.onNextStepClicked}
            onClearDisbursements={this.props.onClearDisbursements}
            getConfirmation={this.props.getConfirmation}
            holidays={this.props.holidays}
            maxForwardWindow={this.props.maxForwardWindow}
            //For show popup by Additional Information
            showAlert={this.props.showAlert}
            closeSaveDealSuccess={this.props.closeSaveDealSuccess}
            currencyDictionary={this.props.currencyDictionary}
            handleSelectCustomerTab={this.props.handleSelectCustomerTab}
            handleSelectContactTab={this.props.handleSelectContactTab}
            systemDate={this.props.systemDate}
            dealStatusWasUpdated={this.props.dealStatusWasUpdated}
            onBookNowClicked={this.props.onBookNowClicked}
            onUncoveredDealsClick={this.props.onUncoveredDealsClick}
            uncoveredDeals={this.props.uncoveredDeals}
            refreshSpot={this.props.refreshSpot}
            onUpdateForwardDate={this.props.onUpdateForwardDate}
            updatedRefreshSpot={this.props.updatedRefreshSpot}
            traderUser={this.props.traderUser}
            loadDeal={this.props.loadDeal}
            />);

        //Deal details
        let objDealHeader = this.props.objDealHeader;
        var dealDetailsTable;
        var dealIDLabel;
        if(objDealHeader.DealHeaderID > 0){
            dealIDLabel = (<div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal ID: {objDealHeader.DealHeaderID}</h3></div>);
            dealDetailsTable = (
                <WizardDealSummary objDealHeader={objDealHeader} disbursements={this.props.disbursements}/>
            );
        }

        return(
            <div className="uk-form-stacked">
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-2-5">
                            <h3>Customer: {this.props.objCustomer["Customer Name"]}</h3>
                            <h3>Contact: {this.props.objContact.Name}</h3>
                        </div>
                        <div className="uk-width-3-5" style={{ display: 'inline-flex', justifyContent: 'flex-end'  }}>
                            {/*dealIDLabel*/}
                            {dealDetailsTable}
                        </div>
                    </div>
                </div>               
                
                {dealScreen}
            </div>
        );
    }
}